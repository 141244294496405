.entry-cover {
    --link:  #179276;
    --offset-top:  0;
    --offset-bottom:  0px;
    --heading: #07556d;
    --subheading: #5e7f80;
    @media (max-width: 479px) {
        --offset-top:  20px;
        --offset-bottom:  20px;
        // --offset-bottom:  65px;  // for mobile nav
    }
}

.entry-logo {
    position: absolute;
    top: 18px;
    right: 18px;
    width: 34px;
    height: 34px;
    border-radius: 8px;
    background: url(/media/logo192.png);
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    // filter: grayscale(100%);
    // opacity: .4;
}

.entry-error {
    color: var(--error);
    font-size: 15px;
}

.entry-info {
    margin-bottom: 20px;
    line-height: 1.4em;
    color: #3c4257;
}
.entry-info.checkbox {
    // margin-bottom: 0px;
    font-size: 16px;
}
.entry-info.checkbox a {
    color: var(--link)
}

.entry-error.signin {
    margin-bottom:  20px;
}

.entry-cover {
    position: fixed;
    top: var(--offset-top);
    left: 0;
    right: 0;
    bottom: var(--offset-bottom);
    display: flex;
    align-items: center;
    justify-content: center;
    @media (max-width: 479px) {
        z-index: 1;        
    }
    /*background-color: rgba(255,255,255,.3);*/
    /*display: none;*/
}

// White box
.entry-content {
    --edge:  60px;
    position: relative;
    box-shadow: 0px 6px 10px rgba(0,0,0,.05);
    // backdrop-filter: blur(6px);
    border: 1px solid rgba(255,255,255,.8);
    background: rgba(255,255,255,.65);
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    border-radius: 12px;
    @media (max-width: 479px) {
        --edge: 10px;
        position: absolute;
        top: 0;
        left: var(--edge);
        right: var(--edge);
        bottom: var(--edge);
    }
    @media (min-width: 480px) {
        min-width: 600px;
    }
}
.entry-content.signin {
    @media (min-width: 480px) {
        max-width: 800px;
        min-width: 550px;
        height: 600px;
    }
}

// Interaction stuff inside whitebox
.entry-container {
    display: inline-block;
    vertical-align: top;
    padding: 100px 50px 100px;
    font-size: 15px;
    // margin: calc(var(--offset-top) * -1) 0 0;
    @media (max-width: 479px) {
        padding: 20px 20px 20px;
        width: 100%;
        font-size: 16px;
    }
    @media (min-width: 480px) {
        width: 500px;
    }
}
.entry-container.signup {
    align-self: flex-end;
    padding: 80px 75px 80px 25px;
    right: 20%;
    height: 100%;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
    @media (max-width: 479px) {
        padding: 20px 20px 20px;;
        right: 0;
        align-self: center;
        height: auto;
    }
    @media (min-width: 480px) {
        width: 520px;
    }
}
.entry-perks.signup, .entry-perks.cart {
    @media (max-width: 479px) {
        display:none;
    }
}

.entry-container.cart {
    align-self: flex-end;
    height: 100%;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    @media (max-width: 479px) {
        padding: 25px 20px 30px;
        width: 100%;
    }
    @media (min-width: 480px) {
        padding: 80px 40px 80px;
        width: 600px;
        height: 650px;
    }

}
.portfolio-box.signup {
    padding: 0;
    position: relative;
    align-self: flex-end;
    width: 100%;
    height: 100%;
    background-color: transparent;
    box-shadow: none;
    backdrop-filter: none;
}

.entry-perks {
    left: 20%;
    width: 440px;
    padding:  40px 40px 40px 75px;
}
.entry-perk {
    /*font-family: var(--accentfont);*/
    font-size: 1.0625em;
    margin-bottom: 6px;
    display: flex;
    /*color: #235a6b;*/
    color: var(--rose);
}
.entry-perk-descr {
    font-size: 1.375em;
    color: var(--headerblack);
    line-height: 1.5em;
    margin-bottom: 30px;
}
.entry-perk-descr.multipoint {
    font-size: 1.2em;
}

.entry-title {
    font-size: 1.25em;
    margin-bottom: 36px;
    font-family: var(--accentbold);
}
.entry-title.signup {
    @media (max-width: 479px) {
        // margin-bottom: 18px;
    }
}
.entry-title.cart {
    margin-bottom: 1.5em;
}

.entry-item {
    margin-bottom: 16px;
    @media (max-width: 479px) {
        margin-bottom: 14px;
    }
}

.entry-label {
    color: rgba(26,26,26,.7);
    font-size: 15px;
    margin-bottom: 7px;
    margin-right: 24px;
    @media (max-width: 479px) {
        font-size: 14px;
    }
}

.entry-field {
    background-color: rgb(243, 247, 250);
    background-color: rgba(0,0,0,.03);
    filter: none;
    border-radius: 6px;
    padding: 13px 20px;
    font-size: 15px;
    width: 100%;
}

.entry-field:focus {
    box-shadow: 0 0 0 1px rgba(50, 151, 211, .3), 0 1px 1px 0 rgba(0, 0, 0, .07), 0 0 0 4px rgba(50, 151, 211, .30);
    background-color: rgb(246, 250, 253);
    background-color: rgba(0,0,0,.01);
    /*filter: drop-shadow(0px 5px 5px rgba(0,0,0,0));*/
    transition: box-shadow .3s, filter .3s;
}
.entry-field.referral {
    width: 70%;
    max-width: 175px;
    margin-bottom: 24px;
}

.entry-msg {
    margin-bottom: 26px;
    line-height: 1.5em;
    font-size: 16px;
    color: #3c4257;
}
.entry-msg.onboarding {
    margin-bottom: 18px;
}

.entry-link {
    cursor: pointer;
    margin-bottom: 10px;
    font-size: 15px;
    margin-top: -10px;
    color:  var(--link);
}

.entry-link.referral {
    display: inline-block;
    background-color: var(--specialops);
    padding: 6px 10px;
    border-radius: 6px;
    margin-bottom: 32px;
    color: #fff;
    box-shadow: 0 10px 26px rgba(7, 14, 60, .35);
}

.entry-change {
    margin-top: 24px;
    color: #4f566b;
    font-size: 16px;
    @media (max-width: 479px) {
        margin-top: 18px
    }
}

.entry-change .link {
    font-weight: 500;
    color: var(--link);
}

.entry-btn {
    cursor: pointer;
    display: inline-block;
    font-size: 16px;
    border-radius: 6px;
    margin-top: 20px;
    padding: 8px 75px 9px;
    display: inline-flex;
    background: var(--accent);/* #0a2540;*/
    color: #fff;
    bottom: 20px;
    right: 20px;
    box-shadow: 0 10px 26px rgba(21, 125, 95, .35);
    @media (max-width: 479px) {
        font-size: 15px;
        font-family: var(--emphfont);
    }
}
.entry-btn.signup {
    margin-top: 12px;
    @media (max-width: 479px) {
        margin-top: 12px;
    }
}

.entry-back {
    cursor: pointer;
    font-size: 1em;
    display: flex;
    margin-bottom:  40px;
    margin-top:  -145px;
    color: #697386 ;
}
.entry-back .material-icons { font-size:1.125em !important }
.entry-back.mobile {
    margin: 0px 0  15px;
    @media (min-width: 480px) {
        display: none
    }
}

.entry-checkbox {
    vertical-align: middle;
    margin-right: 4px;
    margin-left: 2px;
    margin-bottom: 3px;
}

.cart-selection-header {
    color: #3c4257;
    font-size: 1.375em;
    margin: 0px 0 30px;
}
.cart-selection-header.frequency {}
.cart-selection-header.assets {
    font-size: 1.25em;
    margin-bottom: 10px
}

.frequency-option {
    text-align: left;
    margin: 0px 0 20px;
    border-radius: 6px;
    /*border: 1px solid rgba(60,66,87,0.12);;*/
    border: 2px solid white;
    padding: 22px 26px;
    display: block;
    background:  white;
    box-shadow: 0px 6px 10px rgba(0, 0, 0, .05);
    @media (max-width: 479px) {
        width: 100%;
        padding: 18px 20px;
    }
    @media (min-width: 480px) {
        width: 400px;
    }
}
.frequency-option.active {
    border: 2px solid var(--accentlight);
    /*padding: 10px;*/
}
.frequency-option-disabled {
    pointer-events: none;
    opacity: .66;
}

.frequency-option-title {
    font-family: var(--accentbold);
    color: #3c4257;
    margin-top: 0;
    margin-bottom: 8px;
    font-size: 15px;
    @media (max-width: 479px) {
        margin-bottom: 6px;
    }
}

.frequency-option-descr {
    color: #697386;
    font-size: 15px;
    line-height: 1.5em;
    @media (max-width: 479px) {
        line-height: 1.4em;
    }
}
.frequency-option-descr.status { 
    font-size: 14px;
    margin-bottom: 10px;
}

.portfolio-next-btn.cart {
    margin-top: 30px;
    @media (max-width: 479px) {
        margin-top: 15px;
    }
}


.entry-checkout-header {
    font-size: 16px;
    color: var(--grey);
    margin-bottom: 8px;
}
.entry-checkout-container {
    background: rgb(206, 237, 232);
    border-radius: 8px;
    padding: 16px;
    margin-bottom: 40px;
    color: var(--headerblack);
}
.entry-checkout-title {
    font-weight: 500;
    margin-bottom: 10px;
    font-family: var(--accentbold);
    display: inline;
}
.entry-checkout-price {
    float: right
}
