
/* need display block, otherwise margins become adjoining if nested under a parent
that imposes parent > div {display:  inline-block} */
.playable-item {
    padding: 12px 12px;
    padding-left: 10px;
    background: #f6f9fc;
    border-radius: 8px;
    margin: 6px 0;
    padding-left: 16px;
    width:  100%;
    display: flex;
    flex-direction: column;
    font-size: 16px !important;
    background: #fff;
    border: 1px solid #eceef3;
}
.playable-item-row {
    display: flex;
    align-items: center;
    flex-direction: row;
}

.playable-item-row > div, .playable-item > a {
    color: #32325d;
    font-size: .9375em;
}
.playable-item-name {
      // width:  calc(100% - 200px);
    flex-grow: 5;
}
.playable-item-btns {
    display:flex;
    font-size: .9375em;
    padding: 4px 18px;
    text-align: center;
    opacity: .66;
    /*color: #adbdcc !important;*/
    /*color: #697386 !important;*/
    // color: #506968;
    color: #32325d;
    justify-self: flex-end;
    // background-color: rgba(30,30,30,.2);
    border-radius: 10px;
    margin-left: 7px;
    @media (max-width: 479px) {
        padding: 4px 10px;
    }
}
.playable-item-btns:hover {
    color: var(--accent) !important;
    opacity: 1;
}
.playable-item-playpause {}
.playable-item-download {}
.playable-item-drawer {
    position: relative;
    display:block !important;
    /* margin-top: 6px; */
    /*background-color: #f6f9fc;*/
    width: 100%;
    padding-top: 10px;
    border-radius: 6px;
}