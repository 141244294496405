.msg-box-container {
    position: absolute;
    background: #304752;
    padding: 20px;
    border-radius: 8px;
    visibility: hidden;
    opacity: 0;
    width: calc(100% - 40px); // portfolio-box padding
    max-width: 500px;
    bottom: 0px;
    transition: bottom .4s ease-in, visibility 0s .3s, opacity 0.3s linear;
}

.portfolio-box.account .msg-box-container.show {
    bottom: 50px;
}
.portfolio-box.signup .msg-box-container.show {
    bottom: 50px;
}

.msg-box-container.show {
    visibility: visible;
    opacity: 1;
    bottom: 75px;
    transition: opacity .3s linear, bottom .4s ease-out;
}

.msg-box-content {
    color: #fff;
    font-family: 'tf-bar-r';
    line-height: 1.4em;
    margin-bottom: 10px;
}

.msg-box-btn {
    display: inline-block;
    padding: 5px 18px;
    border-radius: 6px;
    margin-right: 8px;
    cursor: pointer;
}

.msg-box-btn.main { background: #e1f9f1 }
.msg-box-btn.dismiss { 
    background: #1b3139;
    color: #fff;
}