.feature-details.right {
  padding-left: 40px;
}

.portfolio-box {
    position: relative;
    padding: 25px 20px;
    border-radius: 16px;
    height: 580px;
    display: inline-block;
    vertical-align: middle;
    background-color: rgba(255,255,255,.7);
    box-shadow: 0 10px 20px 0 rgba(0,0,0,.05);
    // backdrop-filter: blur(6px);
    display: flex;
    flex-direction: column;
    @media (max-width: 479px) {
        width: 100%;
        padding: 18px 16px;
    }
    @media (min-width: 480px) {
        width: 500px;
        min-width: 400px;
    }
}

.portfolio-box.demo {
    @media (max-width: 479px) {
        height: 480px
    }
}

// Mobile Only __________________
.portfolio-asset-separator {
    display: block;
    width:  100%;
}
.portfolio-asset-meta {
    display: inline-block;
    text-align: left;
    padding: 4px 8px 12px;
    width: 100%;
}
.portfolio-asset-meta-stat {
    display: inline-block;
    text-align: left;
    width: 33%
}

.portfolio-asset-meta-stat div {
    display: block;
    text-align: left;
    width: 100%
}
.portfolio-asset-meta-stat:last-child div {
    text-align: right;
}

.portfolio-asset-meta-stat .portfolio-asset-info {
    color: #525f7f;
    margin-bottom: 4px;
}
// Mobile Only Ends ___________________

.portfolio-save-status {
    display: inline-block;
    position: absolute;
    bottom: 20px;
    border-radius: 20px;
    background-color: #dcdcdc;
    padding: 4px 16px;
    color: #fff;
    font-size: .875em;
}

.portfolio-action-panel {

}
.portfolio-next-btn {
    cursor: pointer;
    display: inline-block; 
    font-size: 16px;
    border-radius: 6px;
    padding: 8px 60px 9px;
    background: var(--accent);
    color: #fff;
    align-self: flex-start;
    box-shadow: 0 10px 26px rgba(21, 125, 95, .35);
    @media (max-width: 479px) {
        font-size: 15px;
        font-family: var(--emphfont);
    }
}
.portfolio-upgrade-btn {
    cursor: pointer;
    display: inline-block; 
    font-size: 16px;
    border-radius: 6px;
    padding: 8px 16px 9px;
    margin-left: 10px;
    background: #e1f9f1;
    color: var(--specialops);
    align-self: flex-end;
    // box-shadow: 0 10px 26px rgba(21, 125, 95, .35);
    @media (max-width: 479px) {
        font-size: 15px;
        font-family: var(--emphfont);
    }
}

.portfolio-item {
    margin: 8px 0;
}
/* overflow auto necessary for autofill height: 
see: https://stackoverflow.com/questions/25098042/fill-remaining-vertical-space-with-css-using-displayflex */
/* flex-grow is the have this div fill the remaining space  */
.portfolio-item.assets {
    flex-grow:  50;
    height: calc(100% - 45px - 36px - 32px); // offset from sibling elements (45px for search, 36px for save), 32px for padding, etc.
    @media (max-width: 479px) {
        height: calc(100% - 25px); // offset from sibling elements
    }
}

.portfolio-msg {
  padding: 100px 0 20px;
  color:  rgba(26,26,26,.5);
  font-size: 16px;
}

.portfolio-input-label {
    color: rgba(26,26,26,.7);
    font-size: .9375em;
    margin-bottom: 12px;
    display: inline-block;
    margin-right: 24px;
}

.portfolio-section-label {
  /*border-bottom: 1px solid ;*/
  /*border-bottom: 1px solid rgba(60,66,87,0.12);*/
  /*margin: 10px 0;*/
  // position: relative;
  // height: 50px;
  // @media (max-width: 479px) {
    // height: 32px;
  // }
}

.portfolio-asset-info {
    width: 100%;
}
.portfolio-asset-inforow {
    display: inline-flex;
    flex-direction: row;
    align-items: center;
    flex: 1;
}
.portfolio-asset.header {
    background-color: #fff;
    margin-bottom: 0;
}
.portfolio-asset-inforow.header > div {
    color: #525f7f !important;
}

.portfolio-asset {
    --symbolwidth: 80px;
    --removewidth: 24px;
    --minimizewidth: 80px;
    --pricewidth: 85px;

    padding-left: 10px;
    /* border-top: 1px solid rgba(60,66,87,0.12); */
    padding: 10px 14px;
    background-color: #f6f9fd;
    margin-bottom: 6px;
    font-size: 15px;
    border-radius: 8px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    @media (max-width: 479px) {
        font-size: 15px;
        padding: 10px;
    }
}

.portfolio-asset.wrap {
    flex-wrap: wrap;
}

.portfolio-asset.demo {
    padding: 10px 14px;
}
.portfolio-asset > div {
    color: #32325d;
}
// overlapping fix, playable-item margins overlap when displayed alone, but don't overlap
// when nested inide portfolio-asset
.portfolio-asset .playable-item {
    margin: 4px 0;
}

.portfolio-assets-headerfy {
    padding: 6px 4px 10px;
}

/*31px is the height of the header*/
.portfolio-assets {
    height: calc(100% - 31px);
    overflow-y: scroll;
    border-radius: 8px;
}

.portfolio-asset-symbol {
    width: var(--symbolwidth);
    display: inline-block;
    color: #697386 !important;
}
.portfolio-asset-name {
    display: inline-block;
    width:  calc(100% - var(--symbolwidth) - var(--removewidth));
    @media(max-width: 479px) {
        width:  calc(100% - var(--symbolwidth));
    }   
    /*color: #adbdcc !important;*/
}
.portfolio-asset-name.minimizable {
    width:  calc(100% - var(--symbolwidth) - var(--removewidth) - 3*var(--pricewidth));
    @media(max-width: 479px) {
        width:  calc(100% - var(--symbolwidth) - var(--removewidth));
    }
}

.portfolio-asset-remove {
    display: inline-block;
    font-weight: 800;
    width:  var(--removewidth);
    height: 24px;
    padding: 2px;
    text-align: center;
    color: #adbdcc !important;
    opacity: .66;
}

.portfolio-asset-minimize {
    display: inline-block;
    width:  var(--minimizewidth);
    height: 24px;
    padding: 2px;
    margin-left: 16px;
    text-align: center;
    color: #32325d !important;
    opacity: .6;
}

.portfolio-asset-remove:hover {
    color: #f73558 !important;
}
.portfolio-asset:hover .portfolio-asset-remove {
    opacity: 1;
}
.portfolio-asset-msg {
    padding: 10px 10px 10px;
    width: 75%;
    color: #525f7f !important;
    @media (max-width: 479px) {
        width: 100%;
    }
}

/* need to account for header otherwise won't allow full scroll width */
.scroll-y.account-portfolio {
    height: calc(100% - 80px);
}


.portfolio-asset-price {
    text-align: right;
    width: var(--pricewidth);
}

.portfolio-asset-price.gain, .portfolio-asset-meta-price.gain {
    color: green
}
.portfolio-asset-price.loss, .portfolio-asset-meta-price.loss {
    color: red
}
