.legal-container {
    max-width: 750px;
    margin: 60px auto 60px;
    padding: 65px 70px 100px;
    background-color: #fff;
    font-family: -apple-system,BlinkMacSystemFont,'Segoe UI','Roboto', 'Helvetica','Arial',sans-serif,'Apple Color Emoji','Segoe UI Emoji','Segoe UI Symbol';
    color: #235;
    font-size: 15px;
    @media (max-width: 479px) {
        width: 100%;
        padding: 20px;
        padding-top: 60px;
        padding-bottom: 100px;
        font-size: 15px;
        margin: 0;
    }
}

.legal-bg {
    // background-color: #fcf8f2;
    position: fixed;
    z-index:-1;
    top:0; left:0; right:0; bottom: 0;
}

.legal-container h1 {
    font-size: 1.625em;
    line-height: 1.4em;
    margin-bottom: 18px;
    font-weight: 700;
}

.legal-container h2 {
    font-size: 1.15em;
    margin: 48px 0 18px;
    line-height: 1.4em;
    font-weight: 600;
    @media (max-width: 479px) {
        font-size: 1.0625em;
    }
}

.legal-container h3 {
    font-size: 1.125em;
    line-height: 1.4em;
    font-weight: 600;
    margin: 22px 0 10px;
    @media (max-width: 479px) {
        font-size: 1em;
    }
}

.legal-container h4 {
    font-size: 1.06em;
    line-height: 1.4em;
    font-weight: 600;
    margin: 22px 0 10px;
    @media (max-width: 479px) {
        font-size: 1em;
    }
}

.legal-container p {
    font-size: .95em;
    line-height: 1.4em;
    margin-bottom: 18px;
}