.feature-demo {
    width: 50%;
    display: inline-block;
    vertical-align:  bottom;
    /*padding-left: 20px;*/
    color:  #425466;
    @media (max-width: 479px) {
        width: 95%;
    }
}

.feature-summary {
    position: relative;
    border-radius: 10px;
    padding:  25px 30px 25px;
    margin-top:  30px;
    background-color: #fbf0e3;
    @media (max-width: 479px) {
        padding: 22px;
    }
}
.feature-summary-arrow {
    position: absolute;
    left: -32px;
    bottom: 24px;
    border-width: 16px;
    border-style: solid;
    border-color: transparent #fbf0e3 transparent transparent;
    @media (max-width: 479px) {
        bottom: auto;
        top: -32px;
        left: 32px;
        border-color: transparent transparent #fbf0e3 transparent;
    }
}


#demo-audio {
  margin-bottom: 10px;
}

.feature-summary-date {
  margin-bottom: 10px;
  /*color: #fff;*/
  font-size: 1em;
}

.feature-summary-transcript {
  line-height: 1.5em;
  /*color: #fff;*/
  color: #592263;/*#1a544e;*/
  font-size: 1em;
  max-height: 4.5em;
  overflow-y:  hidden;
  margin-bottom: 14px;
}

.feature-summary-asset {
    // border: 1px solid #592263;
    // color: #fff;
    // background-color: #425466;

    color: #6d6e4e;
    background-color: #f3e4d2;
    display: inline-block;
    padding: 4px 10px;
    border-radius: 6px;
    margin-bottom: 10px;
}

