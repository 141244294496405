.section-feature {
    display: flex;
    padding: 20px 0;
    @media (max-width: 479px) {
        flex-direction: column;
        padding: var(--bleedy) var(--bleedx);
        margin-bottom: 20px;
    }
    @media (min-width: 480px) {
        max-width: var(--maxwidth);
        margin: 0 auto 40px;
    }
}
.section-feature.summary {
    padding-top: 50px;
}

.frosted {
    backdrop-filter: blur(6px);
    // border-radius: 10px;
    // background-color: rgba(255, 255, 255, .3);
    // box-shadow: 0px 7px 10px rgba(0, 0, 0, .05);
}

.feature-content {
    padding: 30px 30px;
    border-radius: 12px;
    @media (max-width: 479px) {
        padding: 30px 20px;
    }
}

.section-feature.centered {
    flex-direction: column;
}

.feature-descr {
    padding-right: 40px;
    padding-top: 20px;
    color: #425466;
    vertical-align: top;
    @media (max-width: 479px) {
        display: block;
        width: 100%;
        padding: 5px
    }
}

.feature-descr.portfolio, .feature-descr.summary {
    @media (min-width: 480px) {
        display: inline-block;
        width: calc(100% - 500px);/* portfolio box width is 500px */
    }
}

.feature-header {
    font-family: var(--accentfont);
    color: var(--headerblack);
    margin-bottom: 10px;
    font-size: 30px;
    font-family: 'tf-cav-sb';
    font-weight: 600;
    color: var(--rose);
    @media (max-width: 479px) {
        font-size: 26px;
    }
}
.feature-header.centered {
    text-align: center;
}

.feature-descr.centered{
    margin: auto;
    display: block;
    padding: 20px 0;
    text-align: center;
    width: 55%;
    @media (max-width: 479px) {
      width: 75%;
    }
}
.feature-details.credibility {
    font-size: 1.25em;
    line-height: 1.4em;
    text-align: center;
    margin: auto;
    color :#fff;
    @media (min-width: 480px) {
        width: 540px;
    }
}

.feature-details {
    font-size: 1.625em;
    line-height: 1.5em;
    margin-bottom: 38px;
    font-family: 'tf-bar-m';
    color: var(--headerblack);
    @media (max-width: 479px) {
        font-size: 1.5em;
    }
}

.feature-try {
    text-align:  left;
    font-size: 1.375em;
    display: flex;
    align-items: center;
    margin: 30px 0 20px;
    color: var(--rose);
    @media (max-width: 479px) {
        font-size: 1.25em;
    }
}
.feature-try.desktop {
    @media (max-width: 479px) {
        display:none
    }
}
.feature-try.mobile {
    @media (min-width: 480px) {
        display:none
    }
}

.feature-try .material-icons {
    font-size: 1.25em !important;
    @media (max-width: 479px) {
        font-size: 1.25em !important;
    }
}

.feature-try .fill-arrow {
    background-color: var(--rose);
    padding: 4px;
    border-radius: 6px;
    font-size: 19px !important;
    opacity: .9;
    color: #fff;
    margin: 0px 4px;
}

.feature-try.portfolio {
    text-align: right;
    justify-content: flex-end;
    @media (max-width: 479px) {
        text-align: left;
        justify-content: flex-start;
    }
}


/*______________________________________*/



/*background:  linear-gradient(320deg, #fbf2db 0%, #f9e4c0 100%);
background-image:  url(./media/lines.png), linear-gradient(135deg, #ffbfd7 0%, #f34394 47%,  #a00c72 100%);

background-image:  url(./media/lines.png), linear-gradient(135deg, #ffbff4 0%, #3a26e6 47%,  #56d6c5 100%);
    background: -webkit-linear-gradient(135deg, #ffccbf 0%, #fcdbbe 48%,  #ffdc90 100%);

    background: linear-gradient(235deg, #BABC4A 0%, #000000 100%), linear-gradient(235deg, #0026AC 0%, #282534 100%), linear-gradient(235deg, #00FFD1 0%, #000000 100%), radial-gradient(120% 185% at 25% -25%, #EEEEEE 0%, #EEEEEE 40%, #7971EA calc(40% + 1px), #7971EA 50%, #393E46 calc(50% + 1px), #393E46 70%, #222831 calc(70% + 1px), #222831 100%), radial-gradient(70% 140% at 90% 10%, #F5F5C6 0%, #F5F5C6 30%, #7DA87B calc(30% + 1px), #7DA87B 60%, #326765 calc(60% + 1px), #326765 80%, #27253D calc(80% + 1px), #27253D 100%);
    background-blend-mode: overlay, lighten, overlay, color-burn, normal;*/