
html { 
  color: rgba(46,51,56,1.00);
  padding: 0 0px;
  margin: 0px 0px 0px;
  width: 100%;
  height: 100%; 
}

body,
div,
dl,
dt,
dd,
ul,
ol,
li,
h1,
h2,
h3,
h4,
h5,
h6,
pre,
code,
form,
fieldset,
legend,
input,
button,
textarea,
select,
p,
blockquote,
th,
td { 
  margin: 0;
  padding: 0;
}

table { 
  border-collapse: collapse;
  border-spacing: 0;
}

fieldset,
img { 
  border: 0;
}

address,
button,
caption,
cite,
code,
dfn,
em,
input,
optgroup,
option,
select,
strong,
textarea,
th,
var {
  font: inherit;
}

del,
ins {
  text-decoration: none;
}

li { 
  list-style: none;
}

caption,
th {
  text-align: left;
}

h1,
h2,
h3,
h4,
h5,
h6 { 
  font-size: 100%;
  font-weight: normal;
}

q:before,
q:after {
  content: '';
}

abbr,
acronym { 
  border: 0;
  font-variant: normal;
}

sup {
  vertical-align: baseline;
}

sub {
  vertical-align: baseline;
}

/*because legend doesn't inherit in IE */
legend {
  color: #000;
}



* { 
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  border: 0;
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none; 
}

*::-webkit-scrollbar {
  display: none;
}

*::selection {
  background-color: #218799;
  background: #183ead;
  color: white;
}

.btn {
  border-radius: 6px;
  background-color: transparent;
}

 
.hide {
  display: none
} 

h1, h2, h3, h4, h5, p {
  line-height: normal
}  

p {
  margin-block-start: 0;
}
 
input, button {
  border: none; 
  background-color: transparent;
  outline: none;
}

input[type=text] {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

a { 
  cursor: pointer;
  text-decoration: none;
}

a:focus, a:hover {
    text-decoration: none
}

ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

img {
  max-width: 100%;
  height: auto;
}

.unselectable {
    pointer-events: none;
}