.section-feature.episode {
    margin-top: -100px;
    margin-bottom: 0
}

.tile-gradient {
    display: flex;
    padding: 20px 0;
    border-radius: 14px;
    // background: rgba(102, 255, 205, .3);
    background: #edffee; // #e2fff8;// rgb(213, 242, 231);
    color: #54575a;
    box-shadow: 0 10px 20px 0 rgba(0,0,0,.05);
    overflow: hidden;
    // backdrop-filter: blur(10px);
    margin-top: 0;
    transition: height .3s;
    @media (max-width: 479px) {
        height: 300px;
        flex-direction: column;
        padding: 20px 30px 20px;
    }
    @media (min-width: 480px) {
        width: 700px;
        height: 170px;
    }
}
.tile-gradient.showPlayer {
    @media (min-width: 480px) {
        width: 700px;
        height: 230px;
    }
    @media (max-width: 479px) {
        height: 420px;
        flex-direction: column;
        padding: 20px 30px 20px;
    }
}

.feature-descr.tile {
    /*color: #54575a;*/
    /*color: #de6821;*/
    /*color: #cc4f1e;*/
    // color: #F5F5C6 !important;
    color: #5c0561 !important;
    padding-top: 0px;
    @media (max-width: 479px) {
        text-align:center;
        padding:0
    }
    @media (min-width: 480px) {
        display: inline-block;
        width: calc(100% - 310px);
        padding-left: 30px;
        padding-right: 15px;
        display: flex;
        flex-direction: column;
        justify-content: center;
    }
}
.feature-details.tile {
    font-size: 1.55em;
    margin-bottom: 18px;
    @media (max-width: 479px) {
        line-height: 1.3em;
        margin-bottom: 15px;
    }
}

.demo-portfolio-container {
  /*background-color:#f6f9fc;*/
    padding: 0 20px 30px;
    width: 310px;
    margin: auto;
    @media (max-width: 479px) {
        padding: 0px;
        width: 100%;
        font-size: 14px
    }
}
.demo-portfolio-name {
  /*color: #de6821;*/
  /*color: #F5F5C6;*/
    // color: #5c0561;
    margin-bottom: 14px;
    text-align: left;
    font-size: 1em;
    font-family: var(--accentbold);
    @media (max-width: 479px) {
        margin-top: 20px;
        font-size: 1.0625em;
    }
}

.demo-asset-container {
    padding: 10px 20px;
    border-radius: 6px;
    margin: 5px 0;
    background-color: white;
    text-align: left;
    font-size: 15px;
    // filter: drop-shadow(0px 4px 3px rgba(0,0,0,.06));
    box-shadow: 0 6px 10px 0 rgba(0,0,0,.05);
    @media (max-width: 479px) {
        padding: 8px 20px;
    }
}

.demo-asset-symbol {
  display: inline-block;
  color: #32325d;
  width: 60px;
}
.demo-asset-name {
    display: inline-block;
    color: #adbdcc;
}

.audio-playpause {
    cursor: pointer;
    display: inline-block;
    background: #fff;
    padding: 4px 18px;
    border-radius: 8px;
    box-shadow: (0px 4px 3px rgba(0, 0, 0, 0.06))
}

.audio-controls-drawer {
    margin-top: 10px;
}