.footer-container {
    display:flex;
    flex-direction: row;
    font-size: 16px;
    max-width: var(--maxwidth);
    margin: 0 auto 0;
    justify-content: space-between;
    padding: 12px 24px;
    // background-color: rgba(235,235,235,.14);
    // border-radius: 12px;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    border-bottom: 0;
    // backdrop-filter: blur(6px);
    max-width: var(--maxwidth);
    justify-self: center;
    width: 840px;
    // box-shadow: 0px 6px 14px rgba(0, 0, 0, .05);
    @media (max-width: 479px) {
        width: 100%;
        padding: 6px 10px;
        padding-bottom: calc(var(--offset-bottom) + 6px);
        font-size: 15px;
    }
}
.footer-container.absolute {
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translate(-50%, 0);
}
.footer-container::after {
    width: 100%;
    height: 4px; 
}

.footer-columns {
    display: flex;
    flex-direction: column;
}
.footer-rows {
    display: flex;
    flex-direction: row;
}

.footer-header {
    font-size: .9375em;
    font-family: var(--accentfont)
}

.footer-links {
    font-size: .9375em;
    padding: 10px 14px;;
    color: #525f7f;
    display: flex;
    align-items: center;
    @media (max-width: 479px) {
        padding: 8px 12px;;
    }
}

.footer-text {
    font-size: .9em;
    line-height: 1.4em;
    color: #8393a0;
    padding: 8px 10px;
    display: flex;
    align-items: center;
}
.footer-text.made {
    @media (max-width: 479px) {
        display:none;
    }
}