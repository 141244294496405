html {
    background-size: 400% 400%;
    color: rgb(25, 25, 39);
    @media (max-width: 479px) {
    }
}

body {
    font-family:  'tf-bar-r', -apple-system,BlinkMacSystemFont,'Segoe UI','Roboto', 'Helvetica','Arial',sans-serif,'Apple Color Emoji','Segoe UI Emoji','Segoe UI Symbol';
    /*font-weight: 500;*/
    -webkit-font-smoothing:  initial;
    text-rendering: optimizeLegibility !important; /* geometricPrecision this makes small fonts too narrow*/
    -webkit-font-feature-settings: "kern" 1;
    -webkit-font-smoothing: antialiased;
    -webkit-font-smoothing: subpixel-antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-variant-ligatures: none;
    font-feature-settings: none;
    font-weight: normal;
    font-size: 16px;
    background-color: #fff8f1;
}

.masterflex {
    display: flex;
    flex-direction: column;
}

.bg {
    display: none;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    pointer-events: none;
    width: 200vw;
    height: 200vh;
    width:  200vw;
    height:  -webkit-fill-available;
    background: radial-gradient(50% 50% at 50% 50%, rgba(255, 0, 122, .15) 0%, transparent 100%);
    transform: translate(00vw,00vh);
    z-index: -1;
}

.bg-dot {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    pointer-events: none;
    width: 100%;
    height: 100%;
    background-color: rgb(255, 245, 251);
    z-index: -1;
    background-color: #fff8f1;// rgba(253,247,237,1) // rgb(255, 245, 251);
}

.mobile-hide {
    @media (max-width: 479px) {
        display: none;
    }
}

.desktop-hide {
    @media (min-width: 480px) {
        display: none !important;
    }
}

// background: linear-gradient(90deg, #161122 21px, transparent 1%) center, linear-gradient(#161122 21px, transparent 1%) center, #a799cc;
//      background-size: 22px 22px;
//     z-index: -1;
//     --bg-color: rgb(255,255,255);
//     --dot-color: rgb(255 210 235);
//     --dot-size: .08em;
//     --dot-space: 0.35em;
//     --dot-dif: calc(var(--dot-space) - var(--dot-size));
//     background: linear-gradient(
// 90deg
// , var(--bg-color) var(--dot-dif), transparent 1%) center, linear-gradient(var(--bg-color) var(--dot-dif), transparent 1%) center, var(--dot-color);
//     background-size: var(--dot-space) var(--dot-space);

.bg-flat {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    pointer-events: none;
    width: 100%;
    height: 100%;
    z-index: -1;
    background-color: #eaeae9;
    background-color: rgba(253,247,237,1) // rgb(255, 245, 251);
}

.material-icons { font-size: 1.25em !important; }
.material-icons-outlined { font-size: 1.25em !important; }

.material-icons-outlined.fill-light {
    background: rgba(255,255,255,0.1);
    border-radius: 3px;
    padding: 5px;
}
.material-icons-outlined.fill {
    border-radius: 3px;
    padding: 5px;
}

.weight-medium {
    font-family: var(--emphfont)
}



:root {
    --accent:  #00a287;
    --accentlight: rgb(0, 211, 149);
    --error:  #9d0025;
    /*--accentfont : 'OptimaLT-DemiBold'*/
    --accentfont : 'tf-pub-sb';
    --accentbold : 'tf-pub-b';
    --emphfont : 'tf-bar-m';
    --mainfont : 'tf-bar-r';
    --bgcolor: #fff8eb; /* #fbf3e5;*/
    --rose: #f10079;
    --teal: #28afcd;/*#129dad;*/ /*#009893;*/
    --symbolcolor: #32325d;
    --specialops: #0a2540;
    --grey: #3c4257;
    --headerblack: #111d3d;
    --darkpurple: #5c0561;
    --link:  #179276;
    --fullwidth : 90%;
    --maxwidth : 62rem;
    --minwidth : 800px;
    // mobile
    --offset-top:  50px;
    --offset-bottom:  0px;
    @media (max-width: 479px) {
        --offset-top:  50px;
        --offset-bottom:  65px;
        --bleedx: 14px;
        --bleedy: 25px;
    }
}


.flexbreak {
    flex-basis: 100%;
    height: 0;
}
.flexrow {
    display: flex;
}
.flexrow.vcenter { 
    align-items: center;
}
.flexrow.hcenter { 
    justify-content: center;
}

.bold {
    font-weight: 700;
}

.disable-select {
    user-select: none; /* supported by Chrome and Opera */
   -webkit-user-select: none; /* Safari */
   -khtml-user-select: none; /* Konqueror HTML */
   -moz-user-select: none; /* Firefox */
   -ms-user-select: none; /* Internet Explorer/Edge */
}

.grabbable {
    cursor: move; /* fallback if grab cursor is unsupported */
    cursor: grab;
    cursor: -moz-grab;
    cursor: -webkit-grab;
}

 /* (Optional) Apply a "closed-hand" cursor during drag operation. */
.grabbable:active {
    cursor: grabbing;
    cursor: -moz-grabbing;
    cursor: -webkit-grabbing;
}

.hoverable {
    cursor: pointer;
}

.scrollable-y {
    overflow-y: scroll;
    height: 100%;
    border-radius: 8px; // rounding corners when scrolling
}
.scrollable-x {
    overflow-x: scroll;
    white-space: nowrap;
    width: 100%;
    border-radius: 8px; // rounding corners when scrolling
}

.divider {
    position: relative;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    overflow: visible;
    padding-top: 16px;
}


.divider hr {
  width: 100%;
  height: 1px;
  border: none;
  background-color: rgba(26,26,26,.1);
}
.divider .text {
    position: absolute;
    left: 50%;
    padding: 0 20px;
    background-color: var(--bgcolor);
    -webkit-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    transform: translateX(-50%);
    /*padding: 0 8px;*/
    /*white-space: nowrap;*/
    /*background-color: #fff;*/
}

// used by section_summary do not remove
.pop-animation {
  animation: pop 0.23s linear 1;
}
@keyframes pop {
  50%  {transform: scale(1.15);}
}

@keyframes gradient {
    0% {
        background-position: 0% 50%;
    }
    50% {
        background-position: 100% 50%;
    }
    100% {
        background-position: 0% 50%;
    }
}

@-webkit-keyframes fadeIn {
    from { opacity: 0; }
      to { opacity: 1; }
}
@keyframes fadeIn {
    from { opacity: 0; }
      to { opacity: 1; }
}
