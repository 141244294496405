.section-feature.about {
    flex-direction: column;
    align-items: center;
    // max-width: 840px;
    // max-width: 100%;
    border-radius: 12px;
    box-shadow: 0 10px 20px 0 rgba(0,0,0,.05);
    background-color: #fff;
    margin-bottom: 0;
    // background-color: rgba(235, 235, 235, 0.14);
    // border-radius: 12px;
    // box-shadow: 0px 6px 14px rgba(0, 0, 0, .05);
}

    
.about-content {
    width: 650px;
    padding: 30px 50px;
    color: var(--headerblack);
    @media (max-width: 479px) {
        width: 100%;
        padding: 5px
    }
}

.feature-header.about {
    margin-bottom: 25px;
}

.about-us-body {
    line-height: 1.5em;
    margin-bottom: 40px;
}
.question-container {
    background-color: #fff4ed;// rgba(12,205,147,.07);
    border-radius: 12px;
    margin-bottom: 8px;
}
.faq-question {
    cursor: pointer;
    display: flex;
    align-items: center;
    font-size: 18px;
    padding: 14px 12px;
    // background-color: rgba(0, 0, 0, .03);
}

.faq-answer {
    font-size: 16.5px;
    padding: 15px 34px 24px;
    line-height: 1.6em;
}

.about-arrow {
    margin-right: 4px;
}