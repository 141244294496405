.tooltip-box {
  --bg : #304752
}

.info-tip {
    width: 18px;
    height: 18px;
    overflow: hidden;
    font-size: .875em;
    padding: 1px 7px;
    font-family: serif;
    font-style: italic;
    background: #125151;
    color: #fff;
    border-radius: 76px;
    cursor: pointer;
    opacity: .3;
    margin: 0 2px;
}
.info-tip:hover {
    opacity:  1;
}

.tooltip-container {
  position: relative;
  display: inline-block;
  vertical-align: top;
  @media (max-width: 479px) {
    display:  none;
  }
}

.tooltip-box {
  position: absolute;
  left:  -20px;
  background: var(--bg);
  font-family: barlow;
  font-size: .875em;
  color: #fff;
  border-radius: 6px;
  bottom: calc(100% + 14px);
  display: none; 
  width: 200px;
  padding: 10px 14px 10px;
  font-family: var(--mainfont);
  font-style: normal;
  z-index: 10;
}

.tooltip-box.visible {
  display: block;
  -webkit-animation: fadeIn .5s;
  animation: fadeIn .5s;
}

.tooltip-arrow {
  position: absolute;
  bottom: -16px;
  left: 24px;
  border-width: 8px;
  border-style: solid;
  border-color: var(--bg) transparent transparent transparent;
}