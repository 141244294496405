.search-area {
    position: relative;
    @media (max-width: 479px) {
        width: 100%;
    }
    @media (min-width: 480px) {
        width: 370px;
    }
    /*margin:  auto;*/
}
.search-results-container {
    position: absolute;
    border-radius: 8px;
    font-size: 16px;
    left: 0;
    top: 62px;
    overflow: hidden;
    padding: 6px;
    /*filter: drop-shadow(0px 5px 5px rgba(0,0,0,.14));*/
    box-shadow: 0 3px 7px rgba(0, 0, 0, .10), 0 0px 1px rgba(0, 0, 0, .16), 0 3px 4px rgba(0, 0, 0,.06);
    /*box-shadow: 0 3px 8px rgba(0, 0, 0, .15),
      0 1px 1px rgba(0, 0, 0, .16),
      0 3px 1px rgba(0, 0, 0, .10);*/
    z-index:  1;
    background: rgba(255,255,255,.65);
    backdrop-filter: blur(10px);
    @media (max-width: 479px) {
        width: 100%;
    }
    @media (min-width: 480px) {
        width: 370px;
    }
}
.search-results-container.hide {
  display: none;
}

.search-result {
  width: 100%;
  padding: 10px 10px;
  text-align: left;
  border-radius: 6px;
  font-size: 16px;
}
.search-result:hover {
  cursor: pointer;
}
.search-result.active {
  background-color: rgba(0,0,0,.04);
  /*background-color: rgb(243, 247, 250);*/
  /*color: #32325d;*/
  color: #0a2540;
}
.search-symbol {
  float: left;
  width: 70px;
  display: inline-block;
  vertical-align: top;
  color: var(--symbolcolor);
}
.search-name {
  display: inline-block;
  vertical-align: top;
  width: calc(100% - 70px);
}

.search-input {
    border-radius: 8px;
    font-size: 1em;
    padding: 5px 8px;
    margin: auto;
    background:  #fff;
    box-shadow: 0px 6px 10px rgba(0, 0, 0,.05);
    background: rgba(255,255,255,.6);
    border: 1px solid #f4f8fd;
    // backdrop-filter: blur(6px);
}

.search-input.focused {
  /*border: 4px solid #bbe4d6;*/
  box-shadow: 0 0 0 1px rgba(50, 151, 211,.3), 0 1px 1px 0 rgba(0, 0, 0,.07), 0 0 0 4px rgba(50, 151, 211,.3);
  filter: none;
  transition:  box-shadow .3s, filter .3s;
}

.search-ticker {
    display: inline-block;
    padding:  8px;
    width: 100%;
}