/* Subscription Section ---------------------------------------------- */

.section-feature.pricing {
    @media (min-width: 480px) {
        max-width: 100%;
        margin-bottom: 0;
    }
    @media (max-width: 479px) {
        padding: 0;
        margin-bottom: 0;
    }
}

.feature-beta-btn {
    cursor: pointer;
    background: var(--specialops);
    text-align:  center;
    border-radius:  6px;
    padding: 10px 20px;
    font-size: 15px;
    margin: 2px 0 4px;
    color:  #fff;
    display: inline-block;
    box-shadow: 0 10px 26px rgba(7,14,60,.35);
}

.feature-header.pricing {
    margin-bottom: 4px;
}

.feature-descr.pricing {
    margin-bottom: 0px;
    padding-bottom: 0px;
}

.section-subscription {
    margin: auto;
    margin-bottom: 20px;
    display: flex;
    justify-content: center;
    @media (min-width: 480px) {
        width: 840px;
        height: 540px;
    }
    @media (max-width: 479px) {
        width: 100%;
        height: auto;
        padding: 0 var(--bleedx);
        margin-bottom: 0px;
        display: block;
    }
}

.subscription-package {
    display: inline-block;
    vertical-align: top;
    width: calc(33% - 2*14px);
    background: white;
    margin: 0 10px;
    border-radius: 12px;
    background-color: rgba(255,255,255,1);
    box-shadow: 0 10px 20px 0 rgba(0,0,0,.05);

    // backdrop-filter: blur(6px);
    @media (min-width: 480px) {
        padding: 24px 24px 30px;
        min-width: 360px;
    }
    @media (max-width: 479px) {
        display:block;
        width: 100%;
        margin: 0 0 20px;
        padding: 30px 25px;
        font-size: 16px
    }
}
.subscription-flag {
    // color: #fff;
    // background-color: #425466;
    color: var(--specialops);
    background-color: #e1f9f1;
    float: right;
    display: inline-block;
    padding: 6px 12px;
    border-radius: 6px;
    margin-bottom: 10px;
}
.subscription-name {
  font-size:  1.0625em;
  font-family:  var(--accentbold);
  margin-bottom: 1.25em;
}

.subscription-details {
    margin-bottom: 1.625em;
    margin-top: 1.25em;
    line-height: 1.5em;
    font-size: 1em;
    @media (max-width: 479px) {
        margin: 18px auto 18px;
    }
}

.subscription-price {
    font-size: 1.625em;
    margin-top: 22px;
    font-family:  var(--accentfont);
    margin-bottom: 16px;
    @media (max-width: 479px) {
        margin: 14px auto 10px;
    }
}

.subscription-price-details {
    color:  #707f98;
    line-height: 1.5em;
    font-size: .9375em;
}

.subscription-basis {
  font-size: 1.125em;
  color: #525f7f;
}

.subscription-feature-list {
}

.subscription-feature {
    margin-bottom: 1.25em;
    font-size: 1em;
    @media (max-width: 479px) {
        font-size: 1em;
        margin-bottom: 15px;
    }
}
.subscription-feature::before {
  content: '\2713';
  color: var(--specialops);
  margin-right: 6px;
  // background-color: rgb(225, 249, 241);
  width: 20px;
  height: 20px;
  float: left;
  text-align: center;
  border-radius: 50%;
}

.subscription-contact {
    margin-top: 16px;
    color:  #4f566b;
    line-height: 1.5em;
    font-size: 15px;
    @media (max-width: 479px) {
        font-size: 16px;
    }
}
.subscription-contact-btn {
    color: var(--link);
    font-weight: 500;
}

.subscription-btn {
    cursor: pointer;
    background: var(--specialops);
    text-align:  center;
    border-radius:  6px;
    padding: 12px 12px;
    margin-top:  35px;
    font-size: 1em;
    color:  #fff;
    display:flex;
    align-items: center;
    box-shadow: 0 10px 26px rgba(7,14,60,.35);
    justify-content: center;
}

.feature-beta {
    border-radius: 12px;
    // background-color: rgba(0,30,30,.02);
    background-color: #edffee ; // rgb(251,240,227);
    width: 80%;
    max-width: 600px;
    margin: 0 auto 30px;
    padding: 25px 30px 16px;
    // border: 1px solid  #fff;// #bfbfbf;
    // box-shadow: 0px 7px 10px rgba(0, 0, 0, .05);
    @media (max-width: 479px) {
        width: calc(100% - 2 * var(--bleedx));
        padding: 30px 25px 20px;
        max-width: 100%;
    }
}

.feature-beta-title {
    color: var(--specialops);
    font-family: 'tf-bar-m';
    margin-bottom: 14px;
}

.feature-beta-descr {
    line-height: 1.5em;
    font-size: 1em;
}

.feature-beta-bar {
    width: 100%;
    border-radius: 20px;
    overflow: hidden;
    margin: 20px 0 12px;
    height: 4px;
    background-color: #fff;
}
.feature-beta-progress {
    height: 4px;
    width: 95%;
    background-color: var(--accent);
}

.feature-beta-status {
    display: inline-block;
    padding: 3px 8px;
    border-radius: 6px;
    margin-bottom: 10px;
    font-size: 15px;
    background-color: #e1f9f1;
}

.feature-beta-status.active {
    color: var(--specialops)
}