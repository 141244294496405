@font-face {
    // PublicoTextWeb-Semibold
    font-family: 'tf-cav-sb';
    src: url('./../fonts/tf-cav-sb.woff2') format('woff2'),
        url('./../fonts/tf-cav-sb.woff') format('woff');
}

@font-face {
    // PublicoTextWeb-Semibold
    font-family: 'tf-pub-sb';
    src: url('./../fonts/tf-pub-sb.woff2') format('woff2'),
        url('./../fonts/tf-pub-sb.woff') format('woff');
}

@font-face {
    // PublicoTextWeb-Bold
    font-family: 'tf-pub-b';
    src: url('./../fonts/tf-pub-b.woff2') format('woff2'),
        url('./../fonts/tf-pub-b.woff') format('woff');
}

@font-face {
    // Barlow Regular
    font-family: 'tf-bar-r';
    src: url('./../fonts/tf-bar-r.woff2') format('woff2'),
        url('./../fonts/tf-bar-r.woff') format('woff');
}

@font-face {
    // Barlow Medium
    font-family: 'tf-bar-m';
    src: url('./../fonts/tf-bar-m.woff2') format('woff2'),
        url('./../fonts/tf-bar-m.woff') format('woff');
}