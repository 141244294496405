/* HERO -------------------------------------------------- */
.hero {
    position: relative;
    text-align: center;
    margin-bottom: 40px;
    background-size: cover;
    font-weight: 500;
    background-position: center;
    position: relative;
    margin-bottom: 0px;
    padding: 80px 6% 100px;
    // background-size: cover;
    // background: rgba(255,255,255,.2);
    // backdrop-filter: blur(6px);
    // box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.06);
    @media (max-width: 479px) {
        font-size:  15px;
        padding: 40px 6% 120px;
    }
    /*background: linear-gradient(238.72deg, #FFB864 0%, #006C4C 100%), radial-gradient(100% 224.43% at 0% 0%, #FCC482 0%, #002E74 100%), linear-gradient(121.28deg, #FFD464 0%, #00553B 100%), linear-gradient(229.79deg, #7534FF 0%, #248900 94.19%), radial-gradient(56.26% 101.79% at 50% 0%, #8F00FF 0%, #493500 100%), linear-gradient(96.19deg, #500052 3.37%, #D5B300 96.63%);*/
/*background-blend-mode: overlay, screen, lighten, hard-light, screen, normal;*/
}

.hero-btn {
    display: inline-block;
    font-size: 15px;
    border-radius: .875em;
    padding: 7px 13px 7px 20px;
    color: var(--specialops);
    display: inline-flex;
    align-items: center;
} 

.hero-btn.main {
    background: var(--rose);
    color: #fff;
    box-shadow: 0 10px 30px rgba(115, 12, 76, .35);
}
.hero-btn.contact {
    font-size: 1.0625em;
}
.hero-header {
    margin: 3.75em auto 2.5em;
    max-width: var(--maxwidth);
}

.hero-lead {
    font-size: 2.9em;
    margin-bottom: 4px;
    font-family: 'tf-cav-sb';
    font-weight: 600;
    color: var(--rose);
    @media (max-width: 479px) {
        font-size: 2.25em;
    }
}
.hero-main {
    // font-size: 2.7em;
    font-size: 3.4em;
    font-family: var(--accentbold);
    font-family: var(--emphfont);
    display: inline-block;
    line-height: 1em;
    color: #212122;
    // color: var(--headerblack);
    // color: transparent;
    // background: url(../media/money.png);
    // background: url(../media/bull2.jpg);
    // background: url(../media/img.png);
    // filter: contrast(1.1) saturate(1.2) brightness(0.9);
    // background: url(../media/nyse.jpeg);
    // background-color: #08582e;
    // background-position: 50% 60%;
    // background-repeat: no-repeat;
    // background-size: cover;
    // background-clip: text;
    @media (max-width: 479px) {
        font-size: 3em;
    }
}

.hero-sub {
    margin: 25px 0 35px;
    font-size: 1.375em;
}
.hero-hl {
    color: var(--rose);
    opacity: 1;
}

/*        background: -webkit-linear-gradient(45deg, #304752, #70d8d0);
/*
        background: linear-gradient(
100deg
, rgb(255, 255, 255) 10%, rgb(255 141 141) 100%), linear-gradient(
46deg
, rgb(157 72 255) 30%, rgb(22 61 255) 110%), radial-gradient(100% 220% at 100% 0%, rgb(255 255 255) 10%, rgb(255, 255, 255) 30%, rgb(125 26 26) 100%), linear-gradient(
49deg
, rgb(191 191 191) 0%, rgb(37 37 37) 100%), linear-gradient(
242deg
, rgb(56 26 68) 0%, rgb(181 255 248) 90%), linear-gradient(
342deg
, rgb(252, 0, 0) 0%, rgb(28 64 123) 75%), linear-gradient(
220deg
, rgb(0 20 255) 0%, rgb(255 226 0) 60%), radial-gradient(80% 110% at 50% 0%, rgb(12 0 0) 0%, rgb(197 156 156) 100%);
    background-blend-mode: overlay, overlay, color-burn, screen, color-burn, difference, color-dodge, normal;*/
