.account-content {
    --fullwidth : 90%;
    --maxwidth : 62rem;
    --minwidth : 800px;
    --sidebarwidth: 200px;
    --mainradius : 10px;
    --sidebarmargin:  0px;
}

.account-section-header {
    font-family: var(--accentfont);
    font-size: 1em;
    margin-bottom: 12px;
    display: inline-block;
    @media (min-width: 480px) {
        display: inline-block;
        margin-right: 10px;
    }
}
.account-section-label {
  /*border-bottom: 1px solid ;*/
  /*border-bottom: 1px solid rgba(60,66,87,0.12);*/
  /*margin: 10px 0;*/
  position: relative;
  height: 50px;
  @media (max-width: 479px) {
    height: 32px;
  }
}

.account-section-header-note {
    font-size: .9em;
    margin-bottom: 16px;
    line-height: 1.5em;
    color: rgba(26, 26, 26, 0.5);
    @media (max-width: 480px) {
        line-height: 1.4em;
    }
}

.account-nav-header {
    font-family: var(--accentfont);
    font-size: 1em;
    padding: 12px;
    @media (max-width: 479px) {
        padding-top:8px;
        padding-bottom:10px;
    }
}

.account-settings-body {
    height: 100%;
    overflow-y: scroll;
    padding-bottom: 40px;
}

.account-content {
    --edge:  60px;
    box-shadow: 0px 6px 10px rgba(0,0,0,.05);
    position: absolute;
    top: var(--offset-top);
    left: var(--edge);
    right: var(--edge);
    bottom: var(--edge);
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    // z-index: 2; // !IMPORTANT Do not enable this or nested tooltip will be blocked by nav
    padding: 6px; // for nav outline
    border-radius: var(--mainradius);
    box-shadow: 0px 6px 10px rgba(0,0,0,.05);
    background-color: rgba(255,255,255,1);
    @media (max-width:479px) {
        top: 0;
        --edge: 0px;
        border-radius: 0;
        flex-direction: column;
        overflow: hidden;
        z-index: 1;
        background-color: rgba(255,255,255,.9);
    }
    @media (min-width:480px) {
        flex-direction: row;
        min-width: 600px;
    }
}

// overflow unset to allow tooltip to appear without getting cutoff 
.account-body {
    padding: 26px 20px;
    display: inline-flex;
    flex-direction: column;
    /*flex-basis: calc(100% - var(--sidebarwidth) - var(--sidebarmargin));*/
    height: 100%;
    overflow: unset;
    @media (max-width:479px) {
        width: 100%;
        height: calc(100% - 90px); // 90px = height of account nav
        // padding: 22px 10px calc(var(--offset-bottom) + 4px); // bottom padding for mobile navigation
        padding: 22px 10px calc(4px); // bottom padding for mobile navigation
    }
    @media (min-width:480px) {
        width: calc(100% - var(--sidebarwidth));
    }
}

.account-nav {
    min-width: var(--sidebarwidth);
    display: inline-block;
    /* padding-right: 40px; */
    /* background: rgba(255,255,255,.66); */
    padding: 8px;
    margin-right: var(--sidebarmargin);
    overflow: hidden;
    // border: 5px solid rgba(255, 255, 255, 0.75);
    background-color: rgb(243,245,250);
    border-radius: calc(var(--mainradius) + 2px);
    height: 100%;
    @media (max-width:479px) {
        min-height: 90px;
        min-width: auto;
        overflow-x: scroll;
        height: 90px;
        white-space: nowrap;
        width: 100%;
    }
}

.account-menu-btn {
    padding: 8px 20px;
    font-size: 15px;
    display: inline-block;
    border-radius: 6px;
    background: #fff;
    cursor: pointer;
}
.account-menu-btn.edit-portfolio {
    float: right;
    background: #f3f5fa;
    color: var(--specialops)
}

.account-mobile-dropdown {
    @media (max-width:479px) {
        z-index: 1;
        top: 50px;
        position: fixed;
        left: 20px;
        background-color: #fff;
        min-width: 200px;
        padding: 5px;
        border-radius: 8px;
        box-shadow: 0px 6px 14px rgba(0, 0, 0, .05);
    }
}


.account-dropdown {
    z-index: 1;
    top: 50px;
    position: absolute;
    left: 20px;
    background-color: #fff;
    border: 1px solid #f4f8fd;
    min-width: 200px;
    padding: 5px;
    border-radius: 8px;
    box-shadow: 0px 6px 14px rgba(0, 0, 0, .05);
    
}
.account-dropdown.edit-portfolio{
    right: 0;
    top: 0;
    left: auto;
    @media (max-width:479px) {
        right: 0;
        top: -10px;
        left: auto;
    }
}
.account-tab {
    cursor: pointer;
    padding: 12px 20px;
    font-size: 15px;
    display: block;
    color: #2e3338;
    /*margin-bottom: 4px;*/
    /*color: #506968;*/
    border-radius: 6px;
    @media (max-width:479px) {
        // display: inline-block;
        padding: 12px 16px;
    }
}
.account-tab:hover {
    color: #294443;
}
.account-tab.active {
    /*color: #304752;*/
    /*background: #304752;*/
    // background: rgba(0,0,0,.04);
    @media (min-width: 480px) {
        background: #fff;
    }
    @media (max-width: 479px) {
        background: #f3f5fa;
    }
    /* color: #304752; */
    font-family: var(--emphfont)
}

.account-start-msg {
    padding: 100px 0 20px;
    color: rgba(26,26,26,.5);
    line-height: 1.5em;
    max-width: 500px;
    font-size: .95em;
}
.account-start-btn {
    display: inline-block;
    font-size: 1em;
    border-radius: 6px;
    padding: 8px 75px 9px;
    background: var(--rose);
    color: #fff;
    box-shadow: 0 10px 30px rgba(168, 27, 114, .35)
}

.portfolio-box.account .portfolio-item.frequency {
    display: none;
}
.portfolio-box.account {
    filter: none;
    width: 100%;
    height: 100%; 
    padding: 0;
    // height: auto;
    background: transparent;
    backdrop-filter: none;
    box-shadow: none;
    height: calc(100% - 36px); // from siblings (header and searchbar), mobile navbar, and extraneous padding & margins
    @media (max-width: 479px) {
        height: calc(100% - 70px - var(--offset-bottom) - 20px); // from siblings (header and searchbar), mobile navbar, and extraneous padding & margins
    }
}
.portfolio-box.account .portfolio-item {
    margin: 8px 0;
}
.portfolio-box.account .portfolio-input-label.search {
    display: none;
}

.account-basic-btn {
    cursor: pointer;
    font-size: 15px;
    margin: 4px 0 4px;
    padding: 6px 14px;
    display: inline-block;
    color: #3c4257;
    background: #ffffff;
    border-radius: 6px;
    @media (max-width: 479px) {
        padding: 6px 14px;
        margin: 8px 0 6px;
    }
}
.account-basic-btn.settings-right {
    float: right;
    vertical-align: top;
    margin: 2px 0 0;
}

.account-basic-btn.warn {
    background: var(--error);
    color: #fff
}
.account-basic-btn.cancel {
    // color: #FF7900;
    background: #3c4257;
    color: #fff;
}

.account-basic-inline-btn {
    cursor: pointer;
    font-size: 14px;
    padding: 2px 4px;
    color: var(--link);
}

.account-tab-label {
    cursor: pointer;
    color: rgba(26,26,26,.7);
    font-size: 15px;
    float: right;
    padding-bottom: 8px;
    display: inline-block;
    padding: 5px 14px 4px 18px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    border-radius: 8px;
    margin-right: 6px;
    border-bottom: 2px solid transparent;
    background: rgb(243,245,250);
    margin-bottom: 12px;
    font-family: 'tf-bar-r';
    @media (max-width: 479px) {
        padding: 3px 18px;
    }
}
.account-tab-label.active {
    background-color: var(--teal);
    color:  #fff;
    /*border-bottom: 2px solid var(--teal);*/
}

.account-input-label {
  color: rgba(26,26,26,.7);
  border: 1px solid rgba(60,66,87,0);
  font-size: 15px;
  margin-bottom: 8px;
  display: inline-block;
  margin-right: 24px;
  // width: 100px;
}
.account-input-label.oneline {
  vertical-align: top;
  margin-top: 6px;
  // width: 150px;
  @media (max-width: 479px) {
        // display:block;
  }
}
.account-input-label.email {
  // width: 150px;
  @media (max-width: 479px) {
        display:block;
  }
}

.account-filter {
  /*border: 1px solid rgba(60,66,87,0.12);*/
  border: 2px solid transparent;
  border-radius: 6px;
  width: 250px;
  padding: 10px 12px;
  font-size: 15px;
  margin-bottom:  12px;
  background-color: rgb(243, 247, 250);
  background-color: rgba(0,0,0,.04);
}

.account-filter:focus {
    border: 2px solid var(--accent);
}

.account-filter input {
    font-size: 15px;
}

.account-info-item {
    padding: 16px 14px 16px;
    margin: 0px 0 8px;
    font-size: 15px;
    // border: 1px solid #eee;
    background: #f6f9fd;
    border-radius: 8px;
    max-width: 600px;
    width: 100%;
}
.account-info-item.no-border {
    border: none;
    padding-left: 0;
    margin-bottom: 0;
}

.account-info-item.change-pwd {
    margin: 0px;
    border: none;
    padding: 3px 0;
}
.account-info-item.change-pwd .account-input-label {
    width: 160px;
}
.account-info-item.change-pwd .account-info-input {
    /*border: 1px solid rgba(60,66,87,.12);*/
}

.account-basic-info {
    padding: 8px 0 8px;
    color: rgba(26, 51, 82, .68);
    font-size: 14px;
    width: 70%;
    line-height: 1.4em;
    @media (max-width: 479px) {
        width: 100%;
        font-size: 15px; 
    }
}
.account-basic-info.block {
    display: block
}

.account-info-input {
  border: 1px solid rgba(60,66,87,.12);
  border-radius: 6px;
  background: #fff;
  width: 250px;
  padding: 10px 12px;
  font-size: 15px;
}
.account-info-input:focus, .account-info-input.active {
  border: 1px solid var(--accent);
}

.account-settings-msg {
    padding: 20px 0 0px;
    font-size: 15px;
    width: 70%;
    line-height: 1.4em;
}
.account-settings-msg.error {
    color: var(--error);
}
.account-settings-msg.success {
    color: var(--accent);
}


.subscription-option {
    cursor: pointer;
    text-align: left;
    margin: 0px 0 14px;
    border-radius: 6px;
    border: 1px solid rgba(60,66,87,.1);;
    padding: 11px;
    width: 190px;
    margin-right: 10px;
    display: inline-flex;
    flex-direction: row;
    align-items: center;
    @media (max-width: 479px) {
        display: flex;
    }
}

.subscription-option-content {
    display: flex; 
    flex-direction: column;
    margin-left: 10px;
}

.subscription-option.active {
    // border-color: var(--accentlight);
    border-color: grey;
    /*padding: 10px;*/
}
.subscription-option.active .material-icons-outlined {
    // color: var(--accentlight);
    color: black;
}
.subscription-option .material-icons-outlined {
    color: rgba(60,66,87,.1);;
}

.subscription-option-title {
    color: #3c4257;
    margin-top: 0;
    margin-bottom: 4px;
    font-size: 15px;
}

.subscription-option-descr {
  color: #697386;
  font-size: .875em;
}




.account-section-date {}
.account-summary-item {}
.account-summary-item-playbtn {}
.account-summary-item-transcript {}
.account-summary-item-name {}
.account-summary-item-symbol {}
