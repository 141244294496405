.nav-container {
    max-width: var(--maxwidth);
    width: var(--fullwidth);
    min-width: var(--minwidth);
    left: calc((100% - min(var(--fullwidth), var(--maxwidth)))/2);
    padding: 18px 0px;
    margin: auto;
    position: fixed;
    z-index: 5;
    text-align: center;
    @media (max-width: 479px) {
        position: fixed;
        bottom: 25px;
        right: 16px;
        left: auto;
        min-width: auto;
        width: auto;
        max-width: auto;
        // background-color: #677;
        padding: 5px;
        overflow-x: scroll;
        white-space: nowrap;
        // background-color: rgba(245, 245, 245, 0.85);
        background-color: #566a7d; // #edf1fd;
        // border: 1px solid #fff;
        // backdrop-filter: blur(8px);
        // box-shadow: 0px 6px 14px rgba(0, 0, 0, .05);
        border-radius: 8px;
        box-shadow: 0px 8px 26px rgba(10, 30, 80, .10);
    }
}
.nav-container.mobileToggle {
    padding: 2px;
}

.nav {
    @media (min-width: 480px) {
        // background-color: rgba(215, 225, 225, 0.16);
        background-color: #fff;
        box-shadow: 0 10px 20px 0 rgba(150,0,0,.05);
        border-radius: 8px;
        width: auto;
        display: inline-block;
        padding: 4px;
    }
}

.nav .nav-link {
    display: inline-block;
    padding: 12px 18px;
    color: #525f7f;
    font-size: .9375em;
    border-radius: 6px;
    transition: .8s background-color;
    @media (max-width: 479px) {
        padding: 12px 16px;
        font-size: 15px;
        display: block;
        text-align: left;
        color: #fff
        // color: #525f7f;
        // color: rgba(255, 255, 255, 0.66);
    }
}

.nav .nav-link.active {
    background-color: rgb(243,245,250);
    color: var(--headerblack);
    font-family: var(--emphfont);
    @media (max-width: 479px) {
        background-color: #f3f5fa;
    }
}

// .nav-logo {
//     width: 100px;
//     height: 100px;
//     display: block;
//     color: var(--rose);
//     background:url('./media/logo.png');
//     background-repeat: no-repeat;
//     background-size: cover;
//     color: #054a5f;/*#005a57;*/
//    // font-family: baked almond; 
// }