
.seeker {
  width:  300px;
}

.search-input.home-demo {
    background-color: rgba(255, 255, 255, 1.0);
    padding: 8px 10px;
    // box-shadow: 0 15px 40px rgba(173, 58, 127, .18)
}

.sources-container {
  text-align: center;
  margin-top: 30px;
}

.source-logo {
    width: 70px;
    opacity: .85;
    height: 70px;
    background-size: contain;
    display: inline-block;
    vertical-align: top;
    background-repeat: no-repeat;
    background-position:  center;
    margin: 0 22px;
    transform: scale(.9);
    -webkit-filter: invert(100%);
    filter: invert(100%);
    @media (max-width: 479px) {
        transform: scale(.85);
    }
}

.sources-bg {
    background-color: #2d394d; // rgb(251,240,227);// #f5f5f7;
    // box-shadow: 0px 7px 10px rgba(0, 0, 0, .05);
}
.source-logo#techcrunch {
  width: 200px;
}
.source-logo#bloomberg {
  width: 125px;
}
.source-logo#reuters {
  width: 160px;
}
.source-logo#cnn {
  width: 68px;
}
.source-logo#cnbc {
  width: 70px;
}
.source-logo#businessinsider {
  width: 110px;
}


.content-container {
/*      max-width: var(--maxwidth);
    width: var(--fullwidth);
    min-width: var(--minwidth);
  margin:  auto;*/
}
.content-main {
  width:  100%;
  /*display: inline-block;*/
  /*vertical-align: top;*/
  margin:  auto;
}

